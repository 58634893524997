// import { Controller } from 'stimulus';
// import Quill from 'quill';

// export default class extends Controller {

//   connect() {
//     this.element.innerHTML = this.quillInnerHTML();
//   }

//   quillInnerHTML() {
//     if (this.params === '') {
//       return;
//     }

//     let emptyElement = document.createElement('div');
//     const quill = new Quill(emptyElement);
//     quill.setContents(JSON.parse(this.params)['ops']);
//     return quill.root.innerHTML;
//   }

//   // Use getter to clean code
//   get params() {
//     return this.data.get('params');
//   }
// }

import { Controller } from 'stimulus';
import Quill from 'quill';

export default class extends Controller {

  connect() {
    this.element.innerHTML = this.quillInnerHTML();
  }

  quillInnerHTML() {
    if (this.params === '') {
      return;
    }

    let emptyElement = document.createElement('div');
    const quill = new Quill(emptyElement);
    quill.setContents(JSON.parse(this.params)['ops']);
    return quill.root.innerHTML;
  }

  // Use getter to clean code
  get params() {
    return this.data.get('params');
  }
}