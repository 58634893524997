/*!
 * Bootstrap-select v1.13.18 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2020 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */

!(function (e, t) {
    void 0 === e && void 0 !== window && (e = window),
        "function" == typeof define && define.amd
            ? define(["jquery"], function (e) {
                  return t(e);
              })
            : "object" == typeof module && module.exports
            ? (module.exports = t(require("jquery")))
            : t(e.jQuery);
})(this, function (e) {
    e.fn.selectpicker.defaults = {
        noneSelectedText: "Nothing selected",
        noneResultsText: "No results match {0}",
        countSelectedText: function (e, t) {
            return 1 == e ? "{0} item selected" : "{0} items selected";
        },
        maxOptionsText: function (e, t) {
            return [1 == e ? "Limit reached ({n} item max)" : "Limit reached ({n} items max)", 1 == t ? "Group limit reached ({n} item max)" : "Group limit reached ({n} items max)"];
        },
        selectAllText: "Select All",
        deselectAllText: "Deselect All",
        multipleSeparator: ", ",
    };
});
