import { Controller } from "stimulus";
import Quill from 'quill';

export default class extends Controller {

  static targets = ['container'];

  connect() {
    this.quillInit();
  }

  quillInit() {
    this.containerTargets.forEach(containerTarget => {
      const quill = new Quill(containerTarget, this.quillOption);
      const textareaElement = containerTarget.nextElementSibling;

      console.log(this.quillOption)

      quill.format('direction', 'ltr');
      quill.format('align', 'left');

      if (textareaElement.type !== 'textarea') {
        console.warn('You have to put <textarea> after <div data-target="quill-editor.container">')
        return;
      }

      if (textareaElement.value !== '') {
        quill.setContents(JSON.parse(textareaElement.value)['ops']);
      }

      quill.on('text-change', function() {
        let delta = quill.getContents();
        textareaElement.value = JSON.stringify(delta);
      })
    })

    var customButton = document.querySelector('#custom-button');
    customButton.addEventListener('click', function() {
      console.log('Clicked!');
    });
  }

  get quillOption() {
    return {
      modules: {
        toolbar: '#toolbar'
      },
      placeholder: 'Quill WYSIWYG',
      readOnly: false,
      theme: 'snow'
    }
  }

  get toolbarOption() {
    return {
      'container': [ // custom dropdown
        ['bold', 'italic', 'underline'],
        ['blockquote'],
        ['link'],
        [{ 'align': [] }],
        [{ 'direction': 'rtl' }],
        [{ 'list': 'ordered'}]
      ]
    }
  }
}




var icons = Quill.import('ui/icons'); 
icons['strike'] = '<i class="fe fe-minus" aria-hidden="true" data-tooltip="true" title=" خط على المكتوب "></i>';
icons['bold'] = '<i class="fe fe-bold" aria-hidden="true" data-tooltip="true" title=" خط عريض "></i>';
icons['italic'] = '<i class="fe fe-italic" aria-hidden="true" data-tooltip="true" title=" خط مائل  "></i>';
icons['underline'] = '<i class="fe fe-underline" aria-hidden="true" data-tooltip="true" title=" اسفل الخط  "></i>';

icons['blockquote'] = '<i class="fe fe-message-circle" aria-hidden="true" data-tooltip="true" title=" اقتباس  "></i>';
icons['link'] = '<i class="fe fe-link" aria-hidden="true" data-tooltip="true" title=" رابط  "></i>';
