// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("controllers");

import "swiper/swiper-bundle.min.css";
import "@client-side-validations/client-side-validations";

require("@client-side-validations/client-side-validations");
require("bootstrap");
require("../styles/application");
require("../js/charts");

import $ from "jquery";

const img = require.context("../img", true);

import Glide, { Breakpoints } from "@glidejs/glide";
import select2 from "../select2/dist/js/select2.min";
import "../js/select2.js";

import * as Clipboard from "clipboard";
import Chart from "chart.js/auto";
import ahoy from "ahoy.js";

require('../bootstrap-select/bootstrap-select');
require('../bootstrap-select/defaults-en_US');
require('../bootstrap-select/defaults-ar_AR');
require('../bootstrap-select/bootstrap-select.css');

$(document).ready(function () {
  $.fn.selectpicker.Constructor.BootstrapVersion = '4';
  $(function () {
    $('.selectpicker').selectpicker();
  });
});

document.addEventListener("turbolinks:load", () => {
  $(window).trigger('load.bs.select.data-api');
  $.fn.selectpicker.Constructor.BootstrapVersion = '4';
  $(function () {
    $('.selectpicker').selectpicker();
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
});


$(document).ready(function () {

  var details_path_id = document.getElementById("details_path");
  console.log(details_path_id);

  if (details_path_id !== null) {
    console.log("Not null");

    var details_path = document.getElementById("details_path");
    var propertyId = details_path.getAttribute("data-property-id");
    var serialNumber = details_path.getAttribute("data-property-serial-number");
    ahoy.track("Visited Property", {
      id: propertyId,
      serial_number: serialNumber,
      path: "details_path",
    });

    var send_email_btn = document.getElementById("send_email_btn");
    document.getElementById("send_email_btn").onclick = function () {
      console.log(send_email_btn);
      var property_id = send_email_btn.getAttribute("data-property-id");
      var serial_number = send_email_btn.getAttribute(
        "data-property-serial-number"
      );
      var agent = send_email_btn.getAttribute("data-agent");
      ahoy.track("Send Email Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var call_btn = document.getElementById("call_btn");
    document.getElementById("call_btn").onclick = function () {
      console.log(call_btn);
      var property_id = call_btn.getAttribute("data-property-id");
      var serial_number = call_btn.getAttribute("data-property-serial-number");
      var agent = call_btn.getAttribute("data-agent");
      ahoy.track("Call Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var chat_btn = document.getElementById("chat_btn");
    document.getElementById("chat_btn").onclick = function () {
      console.log(chat_btn);
      var property_id = chat_btn.getAttribute("data-property-id");
      var serial_number = chat_btn.getAttribute("data-property-serial-number");
      var agent = chat_btn.getAttribute("data-agent");
      ahoy.track("Whatsapp Chat Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var send_email_btn2 = document.getElementById("send_email_btn2");
    document.getElementById("send_email_btn2").onclick = function () {
      console.log(send_email_btn2);
      var property_id = send_email_btn2.getAttribute("data-property-id");
      var serial_number = send_email_btn2.getAttribute(
        "data-property-serial-number"
      );
      var agent = send_email_btn2.getAttribute("data-agent");
      ahoy.track("Send Email Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var call_btn2 = document.getElementById("call_btn2");
    document.getElementById("call_btn2").onclick = function () {
      console.log(call_btn2);
      var property_id = call_btn2.getAttribute("data-property-id");
      var serial_number = call_btn2.getAttribute("data-property-serial-number");
      var agent = call_btn2.getAttribute("data-agent");
      ahoy.track("Call Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var chat_btn2 = document.getElementById("chat_btn2");
    document.getElementById("chat_btn2").onclick = function () {
      console.log(chat_btn2);
      var property_id = chat_btn2.getAttribute("data-property-id");
      var serial_number = chat_btn2.getAttribute("data-property-serial-number");
      var agent = chat_btn2.getAttribute("data-agent");
      ahoy.track("Whatsapp Chat Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    var call_btn3 = document.getElementById("call_btn3");
    document.getElementById("call_btn3").onclick = function () {
      console.log(call_btn3);
      var property_id = call_btn3.getAttribute("data-property-id");
      var serial_number = call_btn3.getAttribute("data-property-serial-number");
      var agent = call_btn3.getAttribute("data-agent");
      ahoy.track("Call Clicked", {
        id: property_id,
        serial_number: serial_number,
        agent: agent,
        path: "details_path",
      });
    };

    /* ---- ---- ---- * ---- ---- ** Clipboard :: Start ** ---- ---- ---- * ---- ---- */
    var clipboard = new Clipboard(".clipboard-btn");
    var clipboard_id = document.getElementById("clipboard_id");

    console.log("Get the clipboard" + clipboard);

    $(".clipboard-btn").tooltip({
      trigger: "click",
      placement: "bottom",
    });

    console.log("Get the clipboard" + clipboard);

    function setTooltip(message) {
      $(".clipboard-btn")
        .tooltip("hide")
        .attr("data-original-title", message)
        .tooltip("show");
      console.log("setTooltip(message)" + message);
    }

    function hideTooltip() {
      setTimeout(function () {
        $(".clipboard-btn").tooltip("hide");
      }, 1500);
      console.log("hideTooltip");
    }

    clipboard.on("success", function (e) {
      console.log("clipboard: success");

      var clipboard_property_id = clipboard_id.getAttribute("data-property-id");
      var clipboard_serial_number = clipboard_id.getAttribute("data-property-serial-number");
      var clipboard_agent = clipboard_id.getAttribute("data-agent");
      ahoy.track("Shared Clicked!", {
        id: clipboard_property_id,
        serial_number: clipboard_serial_number,
        agent: clipboard_agent,
        path: "details_path",
      });

      console.info("Action:", e.action);
      console.info("Text:", e.text);
      console.info("Trigger:", e.trigger);

      setTooltip("Copied!");
      hideTooltip();

      e.clearSelection();
    });

    clipboard.on("error", function (e) {
      console.log("clipboard: error");

      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);

      setTooltip("Failed!");
      hideTooltip();
    });
    /* ---- ---- ---- * ---- ---- ** Clipboard :: end ** ---- ---- ---- * ---- ---- */
  } else {
    console.log("else: null");
  }

  /* ---- ---- ---- * ---- ---- ** Glide.js :: Start ** ---- ---- ---- * ---- ---- */
  const COMPONENT_NAME = "data-glide";
  const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;
  const components = document.querySelectorAll(COMPONENT_SELECTOR);

  for (let i = 0; i < components.length; i++) {
    const options = JSON.parse(
      components[i].getAttribute(COMPONENT_NAME) || "{}"
    );

    let glide = new Glide(components[i], options);

    glide.mount();
  }

  new Glide("#glide1", {
    type: "carousel",
    perView: 5,
    autoplay: 2500,
    hoverpause: true,
    breakpoints: {
      576: { perView: 1 },
      768: { perView: 2 },
      992: { perView: 3 },
      1500: { perView: 4 },
    },
  }).mount();
  /* ---- ---- ---- * ---- ---- ** Glide.js :: End ** ---- ---- ---- * ---- ---- */
});

document.addEventListener("turbolinks:load", () => {
  /* ---- ---- ---- * ---- ---- ** Glide.js :: Start ** ---- ---- ---- * ---- ---- */
  const COMPONENT_NAME = "data-glide";
  const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;
  const components = document.querySelectorAll(COMPONENT_SELECTOR);

  for (let i = 0; i < components.length; i++) {
    const options = JSON.parse(
      components[i].getAttribute(COMPONENT_NAME) || "{}"
    );

    let glide = new Glide(components[i], options);

    glide.mount();
  }

  new Glide("#glide1", {
    type: "carousel",
    perView: 5,
    autoplay: 2500,
    hoverpause: true,
    breakpoints: {
      576: { perView: 1 },
      768: { perView: 2 },
      992: { perView: 3 },
      1500: { perView: 4 },
    },
  }).mount();

  $(".custom-select2").select2();
  $(".info-custom-select").select2();
  $(".account-custom-select").select2();
  $(".custom-select-front").select2();
  $(".custom-select-front-2").select2();
  $(".custom-select-front-3").select2();
  $(".custom-select-back").select2();
  $(".custom-select-edit").select2();
  $(".custom-select-account").select2();
  $(".custom-select-back-2").select2({
    minimumResultsForSearch: -1
  });

});

document.addEventListener("turbolinks:before-cache", function () {
  /* ---- ---- ---- * ---- ---- ** Glide.js :: Start ** ---- ---- ---- * ---- ---- */
  const COMPONENT_NAME = "data-glide";
  const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;
  const components = document.querySelectorAll(COMPONENT_SELECTOR);

  for (let i = 0; i < components.length; i++) {
    const options = JSON.parse(
      components[i].getAttribute(COMPONENT_NAME) || "{}"
    );

    let glide = new Glide(components[i], options);

    glide.mount();
  }

  new Glide("#glide1", {
    type: "carousel",
    perView: 5,
    autoplay: 2500,
    hoverpause: true,
    breakpoints: {
      576: { perView: 1 },
      768: { perView: 2 },
      992: { perView: 3 },
      1500: { perView: 4 },
    },
  }).mount();
  /* ---- ---- ---- * ---- ---- ** Glide.js :: End ** ---- ---- ---- * ---- ---- */

});

document.addEventListener("turbolinks:load", () => {
  $('[data-tooltip="true"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $(".toast").toast({ autohide: false });
  $("#toast").toast("show");

  $(".custom-select2").select2();
  $(".info-custom-select").select2();
  $(".account-custom-select").select2();
  $(".custom-select-front").select2();
  $(".custom-select-front-2").select2();
  $(".custom-select-front-3").select2();
  $(".custom-select-back").select2();
  $(".custom-select-edit").select2();
  $(".custom-select-account").select2();
  $(".custom-select-back-2").select2({
    minimumResultsForSearch: -1
  });
});

// $(document).ready(function (){
//   console.log("1st")
//   let content2 = document.getElementById("groupdepID01");

//   var child = content2.firstElementChild
//   child.classList.add("d-none");
//   console.log(content.firstElementChild);
//   let children2 = content2.children;
//   console.log(children2);
//   console.log("1st")
// })

// document.addEventListener("turbolinks:load", () => {
//   console.log("2nd")
//   $("#groupdepID01 > .ql-toolbar").addClass('highLightButton');

//   let content = document.getElementById("groupdepID01");
//   console.log(content);
//   console.log(content.firstElementChild);

//   let children = content.children[0];
//   console.log(children);

//   console.log("2nd")
// });

// document.addEventListener("turbolinks:before-cache", function () {
//   console.log("3rd")
//   // $("#groupdepID01 > .ql-toolbar").eq(0).addClass("d-none");
//   let content = document.getElementById("groupdepID01");
//   console.log(content)
//   var child = content.firstElementChild
//   child.classList.add("d-none");
//   console.log(content.firstElementChild);
//   let children = content.children;
//   console.log(children);
//   console.log("3rd")
// });

document.addEventListener("turbolinks:before-cache", function () {
  $('[data-tooltip="true"]').tooltip("hide");

  $(".custom-select2").select2();
  $(".info-custom-select").select2();
  $(".account-custom-select").select2();
  $(".custom-select-front").select2();
  $(".custom-select-front-2").select2();
  $(".custom-select-front-3").select2();
  $(".custom-select-back").select2();
  $(".custom-select-edit").select2();
  $(".custom-select-account").select2();
  $(".custom-select-back-2").select2({
    minimumResultsForSearch: -1
  });
});


/* ---- ---- ---- * ---- ---- ** Chart.js :: Function ** ---- ---- ---- * ---- ---- */
document.addEventListener("turbolinks:load", function () {
  const fonts = { base: 'Cerebri Sans' };
  Chart.defaults.font.family = fonts.base;
  Chart.defaults.font.size = 13;

  var ctx = document.getElementById("myChart").getContext("2d");

  var labelTooltipData = JSON.parse(ctx.canvas.dataset.legends);
  var titleTooltipData = JSON.parse(ctx.canvas.dataset.labels);

  var chartFunc = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: JSON.parse(ctx.canvas.dataset.labels),
      datasets: [
        {
          label: JSON.parse(ctx.canvas.dataset.labels),
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.colors),
        },
      ],
    },
    options: {
      options,
      maintainAspectRatio: false,
      cutout: "83%",
      borderWidth: 2,
      plugins: {
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            // Tooltip Element
            var tooltip = document.getElementById("chartjs-tooltip");
            var chartType = this._chart.config.type;
            var model = context.tooltip;

            // Create tooltip if doesn't exist
            if (!tooltip) {
              tooltip = document.createElement("div");

              tooltip.setAttribute("id", "chartjs-tooltip");
              tooltip.setAttribute("role", "tooltip");
              tooltip.classList.add("popover");
              tooltip.classList.add("bs-popover-top");

              document.body.appendChild(tooltip);
            }

            // Hide tooltip if not visible
            if (model.opacity === 0) {
              tooltip.style.opacity = 0;
              return;
            }

            if (model.body) {
              var title = model.title || [];
              var body = model.body.map(function (body) {
                return body.lines;
              });

              // Add arrow
              var content = '<div class="arrow"></div>';

              // Add title
              title.forEach(function (title) {
                content +=
                  '<h3 class="popover-header text-center">' + title + "</h3>";
              });

              // Add content
              body.forEach(function (body, i) {
                var colors = model.labelColors[i];
                var indicatorColor =
                  chartType === "line" &&
                    colors.borderColor !== "rgba(0,0,0,0.1)"
                    ? colors.borderColor
                    : colors.backgroundColor;
                var indicator =
                  '<span class="popover-body-indicator" style="background-color: ' +
                  indicatorColor +
                  '"></span>';
                var justifyContent =
                  body.length > 1
                    ? "justify-content-left"
                    : "justify-content-center";

                content +=
                  '<div class="popover-body d-flex align-items-center ' +
                  justifyContent +
                  '">' +
                  indicator +
                  body +
                  "</div>";
              });

              tooltip.innerHTML = content;
            }

            var canvas = context.chart.canvas;
            var canvasRect = canvas.getBoundingClientRect();

            var scrollTop =
              window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop ||
              0;
            var scrollLeft =
              window.pageXOffset ||
              document.documentElement.scrollLeft ||
              document.body.scrollLeft ||
              0;

            var canvasTop = canvasRect.top + scrollTop;
            var canvasLeft = canvasRect.left + scrollLeft;

            var tooltipWidth = tooltip.offsetWidth;
            var tooltipHeight = tooltip.offsetHeight;

            var top = canvasTop + model.caretY - tooltipHeight - 16;
            var left = canvasLeft + model.caretX - tooltipWidth / 4;

            tooltip.style.opacity = 1;
            tooltip.style.top = top + "px";
            tooltip.style.left = left + "px";
            tooltip.style.visibility = "visible";
          },
          callbacks: {
            title: function (tooltipItem, data) {
              var titleValue = titleTooltipData[tooltipItem[0].dataIndex];
              if (titleValue === "Land") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-crop"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Apartment") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-database"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Flat") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-maximize"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Villa") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-home"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Shop") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-shopping-bag"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Warehouse") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-layout"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Workshop") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-tool"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  titleValue +
                  "</span>"
                );
              }
            },
            label: function (context) {
              var value = labelTooltipData[context.dataIndex];
              return '<span class="popover-body-value">' + value + "</span>";
            },
          },
        },
        legend: {
          display: true,
          labels: {
            color: "rgb(149,170,201)",
            usePointStyle: true,
            boxWidth: 6,
          },
          position: "bottom",
        },
      },
    },
  });

  var options = {
    crossTextFontStyle: ["bold"],
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };




  var soldHoldCanvas = document.getElementById("soldChart").getContext("2d");
  var titleSoldData = JSON.parse(soldHoldCanvas.canvas.dataset.sold)
  var titleHoldData = JSON.parse(soldHoldCanvas.canvas.dataset.hold)

  var dataFirst = {
    label: "Sold",
    data: JSON.parse(soldHoldCanvas.canvas.dataset.sold),
    tension: 0.2,
    fill: false,
    id: "y1",
    borderColor: 'rgba(0, 217, 126, 1)'
  };

  var dataSecond = {
    label: "On-Hold",
    data: JSON.parse(soldHoldCanvas.canvas.dataset.hold),
    tension: 0.2,
    fill: false,
    id: "y2",
    borderColor: 'rgba(149, 170, 201, 1)'
  };

  var speedData = {
    labels: JSON.parse(ctx.canvas.dataset.labels),
    datasets: [dataFirst, dataSecond]
  };

  var chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        enabled: false,
        external: function (context) {
          // Tooltip Element
          var tooltip = document.getElementById("chartjs-tooltip");
          var chartType = this._chart.config.type;
          var model = context.tooltip;

          // Create tooltip if doesn't exist
          if (!tooltip) {
            tooltip = document.createElement("div");

            tooltip.setAttribute("id", "chartjs-tooltip");
            tooltip.setAttribute("role", "tooltip");
            tooltip.classList.add("popover");
            tooltip.classList.add("bs-popover-top");

            document.body.appendChild(tooltip);
          }

          // Hide tooltip if not visible
          if (model.opacity === 0) {
            tooltip.style.opacity = 0;
            return;
          }

          if (model.body) {
            var title = model.title || [];
            var body = model.body.map(function (body) {
              return body.lines;
            });

            // Add arrow
            var content = '<div class="arrow"></div>';

            // Add title
            title.forEach(function (title) {
              content +=
                '<h3 class="popover-header text-center">' + title + "</h3>";
            });

            // Add content
            body.forEach(function (body, i) {
              var colors = model.labelColors[i];
              var indicatorColor =
                chartType === "line" &&
                  colors.borderColor !== "rgba(0,0,0,0.1)"
                  ? colors.borderColor
                  : colors.backgroundColor;
              var indicator =
                '<span class="popover-body-indicator" style="background-color: ' +
                indicatorColor +
                '"></span>';
              var justifyContent =
                body.length > 1
                  ? "justify-content-left"
                  : "justify-content-center";

              content +=
                '<div class="popover-body d-flex align-items-center ' +
                justifyContent +
                '">' +
                indicator +
                body +
                "</div>";
            });

            tooltip.innerHTML = content;
          }

          var canvas = context.chart.canvas;
          var canvasRect = canvas.getBoundingClientRect();

          var scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
          var scrollLeft =
            window.pageXOffset ||
            document.documentElement.scrollLeft ||
            document.body.scrollLeft ||
            0;

          var canvasTop = canvasRect.top + scrollTop;
          var canvasLeft = canvasRect.left + scrollLeft;

          var tooltipWidth = tooltip.offsetWidth;
          var tooltipHeight = tooltip.offsetHeight;

          var top = canvasTop + model.caretY - tooltipHeight - 16;
          var left = canvasLeft + model.caretX - tooltipWidth / 4;

          tooltip.style.opacity = 1;
          tooltip.style.top = top + "px";
          tooltip.style.left = left + "px";
          tooltip.style.visibility = "visible";
        },
        callbacks: {
          title: function (tooltipItem, data) {
            var titleValue = titleTooltipData[tooltipItem[0].dataIndex];

            if (titleValue === "Land") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-crop"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Apartment") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-database"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Flat") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-maximize"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Villa") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-home"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Shop") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-shopping-bag"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Warehouse") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-layout"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else if (titleValue === "Workshop") {
              return (
                '<span class="popover-body-label mr-auto">' +
                '<i class="fe fe-tool"></i>' +
                " " +
                titleValue +
                "</span>"
              );
            } else {
              return (
                '<span class="popover-body-label mr-auto">' +
                titleValue +
                "</span>"
              );
            }
          },
          label: function (context) {
            console.log(context)
            if (context.dataset.id === 'y1') {
              console.log('hello: ' + context.dataset.id);
              var value = titleSoldData[context.dataIndex] + " " + "Sold";
            }
            if (context.dataset.id === 'y2') {
              console.log('hello2: ' + context.dataset.id);
              var value = titleHoldData[context.dataIndex] + " " + "On-Hold";
            }
            return '<span class="popover-body-value">' + value + "</span>";
          },
        },
      },
      legend: {
        display: true,
        labels: {
          font: {
            size: 12,
            family: "'Cerebri Sans', 'Tajawal'",
            weight: "500",
          },
          color: 'rgb(149,170,201)',
          usePointStyle: true,
          boxWidth: 6,
        },
        position: "bottom",
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        }
      },
      y: {
        grid: {
          display: false,
          min: 0,
          ticks: {
            stepSize: 1.0,
          },
          drawBorder: false,
        },
      }
    }
  };

  var lineChart = new Chart(soldHoldCanvas, {
    type: 'line',
    data: speedData,
    options: chartOptions
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  var ctx = document.getElementById("myChart").getContext("2d");

  var labelTooltipData = JSON.parse(ctx.canvas.dataset.legends);
  var titleTooltipData = JSON.parse(ctx.canvas.dataset.labels);

  var chartFunc = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: JSON.parse(ctx.canvas.dataset.labels),
      datasets: [
        {
          label: JSON.parse(ctx.canvas.dataset.labels),
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.colors),
        },
      ],
    },
    options: {
      options,
      maintainAspectRatio: false,
      cutout: "83%",
      borderWidth: 2,
      plugins: {
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderRadius: 4,
          color: "white",
          font: {
            weight: "bold",
          },
          formatter: Math.round,
          padding: 6,
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            // Tooltip Element
            var tooltip = document.getElementById("chartjs-tooltip");
            var chartType = this._chart.config.type;
            var model = context.tooltip;

            // Create tooltip if doesn't exist
            if (!tooltip) {
              tooltip = document.createElement("div");

              tooltip.setAttribute("id", "chartjs-tooltip");
              tooltip.setAttribute("role", "tooltip");
              tooltip.classList.add("popover");
              tooltip.classList.add("bs-popover-top");

              document.body.appendChild(tooltip);
            }

            // Hide tooltip if not visible
            if (model.opacity === 0) {
              tooltip.style.opacity = 0;
              return;
            }

            if (model.body) {
              var title = model.title || [];
              var body = model.body.map(function (body) {
                return body.lines;
              });

              // Add arrow
              var content = '<div class="arrow"></div>';

              // Add title
              title.forEach(function (title) {
                content +=
                  '<h3 class="popover-header text-center">' + title + "</h3>";
              });

              // Add content
              body.forEach(function (body, i) {
                var colors = model.labelColors[i];
                var indicatorColor =
                  chartType === "line" &&
                    colors.borderColor !== "rgba(0,0,0,0.1)"
                    ? colors.borderColor
                    : colors.backgroundColor;
                var indicator =
                  '<span class="popover-body-indicator" style="background-color: ' +
                  indicatorColor +
                  '"></span>';
                var justifyContent =
                  body.length > 1
                    ? "justify-content-left"
                    : "justify-content-center";

                content +=
                  '<div class="popover-body d-flex align-items-center ' +
                  justifyContent +
                  '">' +
                  indicator +
                  body +
                  "</div>";
              });

              tooltip.innerHTML = content;
            }

            var canvas = context.chart.canvas;
            var canvasRect = canvas.getBoundingClientRect();

            var scrollTop =
              window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop ||
              0;
            var scrollLeft =
              window.pageXOffset ||
              document.documentElement.scrollLeft ||
              document.body.scrollLeft ||
              0;

            var canvasTop = canvasRect.top + scrollTop;
            var canvasLeft = canvasRect.left + scrollLeft;

            var tooltipWidth = tooltip.offsetWidth;
            var tooltipHeight = tooltip.offsetHeight;

            var top = canvasTop + model.caretY - tooltipHeight - 16;
            var left = canvasLeft + model.caretX - tooltipWidth / 4;

            tooltip.style.opacity = 1;
            tooltip.style.top = top + "px";
            tooltip.style.left = left + "px";
            tooltip.style.visibility = "visible";
          },
          callbacks: {
            title: function (tooltipItem, data) {
              var titleValue = titleTooltipData[tooltipItem[0].dataIndex];
              if (titleValue === "Land") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-crop"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Apartment") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-database"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Flat") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-maximize"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Villa") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-home"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Shop") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-shopping-bag"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Warehouse") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-layout"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else if (titleValue === "Workshop") {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  '<i class="fe fe-tool"></i>' +
                  " " +
                  titleValue +
                  "</span>"
                );
              } else {
                return (
                  '<span class="popover-body-label mr-auto">' +
                  titleValue +
                  "</span>"
                );
              }
            },
            label: function (context) {
              var value = labelTooltipData[context.dataIndex];
              return '<span class="popover-body-value">' + value + "</span>";
            },
          },
        },
        legend: {
          display: true,
          labels: {
            font: {
              size: 12,
              family: "Cerebri Sans",
              weight: "500",
            },
            color: "rgb(149,170,201)",
            usePointStyle: true,
            boxWidth: 6,
          },
          position: "bottom",
        },
      },
    },
  });

  var options = {
    crossTextFontStyle: ["bold"],
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
});
/* ---- ---- ---- * ---- ---- ** Chart.js :: End ** ---- ---- ---- * ---- ---- */


/* ---- ---- ---- * ---- ---- ** MapBox-GL.js :: Start ** ---- ---- ---- * ---- ---- */
import mapboxgl from '!mapbox-gl'

document.addEventListener("turbolinks:load", () => {

  var mapBox = document.getElementById("mapBox");
  var latitude = mapBox.getAttribute("data-latitude");
  var longitude = mapBox.getAttribute("data-longitude");
  console.log("longitude: " + longitude + "," + "latitude: " + latitude);

  mapboxgl.accessToken = 'pk.eyJ1IjoiYWxraGFiYXoiLCJhIjoiY2tvZnY5MWRkMHE1YTMwb2EwaGMzeXE4ayJ9.WbdzPnnqL_pQCfR9odwtsg';
  var mapp = new mapboxgl.Map({
    container: "mapBox",
    style: "mapbox://styles/mapbox/streets-v9",
    zoom: 12,
    center: [longitude, latitude],
    scrollZoom: true,
    interactive: false
  });

  mapp.on("load", function () {
    /* Image: An image is loaded and added to the map. */
    mapp.loadImage("https://i.imgur.com/MK4NUzI.png", function (error, image) {
      if (error) throw error;
      mapp.addImage("custom-marker", image);
      /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
      mapp.addLayer({
        id: "markers",
        type: "symbol",
        /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
        source: {
          type: "geojson",
          data: {
            type: 'FeatureCollection',
            features: [{
              type: 'Feature',
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [longitude, latitude],
              }
            }]
          }
        },
        layout: {
          "icon-image": "custom-marker",
        }
      });
    });
  });
});

document.addEventListener("turbolinks:before-cache", () => {

  var mapBox = document.getElementById("mapBox");
  var latitude = mapBox.getAttribute("data-latitude");
  var longitude = mapBox.getAttribute("data-longitude");
  console.log("longitude: " + longitude + "," + "latitude: " + latitude);

  mapboxgl.accessToken = 'pk.eyJ1IjoiYWxraGFiYXoiLCJhIjoiY2tvZnY5MWRkMHE1YTMwb2EwaGMzeXE4ayJ9.WbdzPnnqL_pQCfR9odwtsg';
  var mapp = new mapboxgl.Map({
    container: "mapBox",
    style: "mapbox://styles/mapbox/streets-v9",
    zoom: 13,
    center: [longitude, latitude],
    scrollZoom: true,
    interactive: false
  });

  mapp.on("load", function () {
    /* Image: An image is loaded and added to the map. */
    mapp.loadImage("https://i.imgur.com/MK4NUzI.png", function (error, image) {
      if (error) throw error;
      mapp.addImage("custom-marker", image);
      /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
      mapp.addLayer({
        id: "markers",
        type: "symbol",
        /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
        source: {
          type: "geojson",
          data: {
            type: 'FeatureCollection',
            features: [{
              type: 'Feature',
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [longitude, latitude],
              }
            }]
          }
        },
        layout: {
          "icon-image": "custom-marker",
        }
      });
    });
  });
});
/* ---- ---- ---- * ---- ---- ** MapBox-GL.js :: End ** ---- ---- ---- * ---- ---- */

function reload() {
  var container = document.getElementById("yourDiv");
  var content = container.innerHTML;
  container.innerHTML = content;

  //this line is to watch the result in console , you can remove it later	
  console.log("Refreshed");
}



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
