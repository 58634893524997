/*!
 * Bootstrap-select v1.13.18 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2020 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */

!(function (e, t) {
    void 0 === e && void 0 !== window && (e = window),
        "function" == typeof define && define.amd
            ? define(["jquery"], function (e) {
                  return t(e);
              })
            : "object" == typeof module && module.exports
            ? (module.exports = t(require("jquery")))
            : t(e.jQuery);
})(this, function (e) {
    e.fn.selectpicker.defaults = {
        noneSelectedText: "\u0644\u0645 \u064a\u062a\u0645 \u0625\u062e\u062a\u064a\u0627\u0631 \u0634\u0626",
        noneResultsText: "\u0644\u0627 \u062a\u0648\u062c\u062f \u0646\u062a\u0627\u0626\u062c \u0645\u0637\u0627\u0628\u0642\u0629 \u0644\u0640 {0}",
        countSelectedText: function (e, t) {
            return 1 == e ? "{0} \u062e\u064a\u0627\u0631 \u062a\u0645 \u0625\u062e\u062a\u064a\u0627\u0631\u0647" : "{0} \u062e\u064a\u0627\u0631\u0627\u062a \u062a\u0645\u062a \u0625\u062e\u062a\u064a\u0627\u0631\u0647\u0627";
        },
        maxOptionsText: function (e, t) {
            return [
                1 == e
                    ? "\u062a\u062e\u0637\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d ({n} \u062e\u064a\u0627\u0631 \u0628\u062d\u062f \u0623\u0642\u0635\u0649)"
                    : "\u062a\u062e\u0637\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d ({n} \u062e\u064a\u0627\u0631\u0627\u062a \u0628\u062d\u062f \u0623\u0642\u0635\u0649)",
                1 == t
                    ? "\u062a\u062e\u0637\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0644\u0644\u0645\u062c\u0645\u0648\u0639\u0629 ({n} \u062e\u064a\u0627\u0631 \u0628\u062d\u062f \u0623\u0642\u0635\u0649)"
                    : "\u062a\u062e\u0637\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0644\u0644\u0645\u062c\u0645\u0648\u0639\u0629 ({n} \u062e\u064a\u0627\u0631\u0627\u062a \u0628\u062d\u062f \u0623\u0642\u0635\u0649)",
            ];
        },
        selectAllText: "\u0625\u062e\u062a\u064a\u0627\u0631 \u0627\u0644\u062c\u0645\u064a\u0639",
        deselectAllText: "\u0625\u0644\u063a\u0627\u0621 \u0625\u062e\u062a\u064a\u0627\u0631 \u0627\u0644\u062c\u0645\u064a\u0639",
        multipleSeparator: "\u060c ",
    };
});

